// import Image from 'next/image';

import * as React from 'react';
import { AppProps } from 'next/app';
import styled, {
  ThemeProvider as StyledThemeProvider,
} from 'styled-components';
import { ThemeProvider } from 'next-themes';
import { MantineProvider } from '@mantine/core';

import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiConfig } from 'wagmi';

import { GlobalStyles } from '../components/common/styles/GlobalStyle';
import FlexRowWrapper from 'ui/wrappers/FlexRowWrapper';

import { lightTheme } from '../theme';
import { emotionCache } from '../utils/emotion-cache';

import { appInfo, wagmiClient, configuredChains } from 'common/rainbowkit';

const AppWrapper = styled.main`
  & > .navbar ${FlexRowWrapper} {
    height: 55px;
    align-items: center;
    justify-content: space-between;
  }
`;

export default function App({ Component, pageProps }: AppProps) {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!mounted) return null;

  return (
    <>
      <ThemeProvider defaultTheme="light" enableSystem={false}>
        <StyledThemeProvider theme={lightTheme}>
          <GlobalStyles />
          <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider appInfo={appInfo} chains={configuredChains}>
              <MantineProvider emotionCache={emotionCache}>
                <AppWrapper>
                  <Component {...pageProps} />
                </AppWrapper>
              </MantineProvider>
            </RainbowKitProvider>
          </WagmiConfig>
        </StyledThemeProvider>
      </ThemeProvider>
    </>
  );
}
