import { ThemeObj } from './ts/interfaces/theme.interface';

export const darkTheme: ThemeObj = {
  fontFamily: {
    primary: 'Inter',
  },
  accent: {
    primary: {
      100: '#ffee03',
      200: '#ffee03',
    },
    secondary: {
      100: '#ffee03',
      200: '#ffee03',
    },
  },
  background: {
    100: '#141416',
  },
  state: {
    error: '#F2555A',
    success: '#10B3A3',
    warning: '#FFCB47',
    info: '#00B1CC',
  },
  text: {
    100: '#FCFCFD',
    200: '#F4F5F6',
    300: '#B1B5C3',
    400: '#777E90',
    500: '#353945',
    600: '#23262F',
    700: '#141416',
    800: '#1A1D1F',
  },
};

export const lightTheme: ThemeObj = {
  fontFamily: {
    primary: 'Inter',
  },
  accent: {
    primary: {
      100: '#1F5C4B',
      200: '#824CC6',
    },
    secondary: {
      100: '#FAA550',
      200: '#C89840',
    },
  },
  background: {
    100: '#E5E5E5',
  },
  state: {
    error: '#EB5757',
    success: '',
    warning: '',
    info: '',
  },
  text: {
    100: '#141416',
    200: '#23262F',
    300: '#353945',
    400: '#777E90',
    500: '#B1B5C3',
    600: '#F4F5F6',
    700: '#FCFCFD',
    800: '#FFF',
  },
};
